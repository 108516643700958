import React from "react";
import ProjectsCard from "../../components/ProjectsCard";
const items = [
  {
    title: "🚀 Python Developer (Web Scraping Expert)",
    content: "Remote / On-site",
    link: "python-developer",
  },
  {
    title: "🌍 JavaScript Developer (Chrome Extension Specialist)",
    content: "Remote / On-site",
    link: "javascript-developer",
  },
  {
    title: "⚡ Angular Developer (Lead Front-End)",
    content: "Remote / On-site",
    link: "angular-developer",
  },
];

const Projects = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-xs-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17 "
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-9 ">
                At Cloudstack, we’re on a mission to simplify work and
                revolutionize customer engagement through cutting-edge SaaS
                solutions. We believe in fostering a culture of innovation,
                collaboration, and continuous learning, where every team member
                plays a vital role in shaping the future of technology. <br />
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-xs-center">
          <div
            className="text-center mb-7 mb-lg-17 mt-xs-5 mt-xl-2 col-xl-9 col-lg-8 col-md-8 col-xs-8"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <p className="">
              If you’re passionate about building impactful solutions, working
              with a dynamic and talented team, and growing both personally and
              professionally, we’d love to hear from you!
            </p>
          </div>
        </div>
        <div className="col justify-content-center mt-5">
          {items.map((item, index) => (
            <div
              className="col-xl-9 col-lg-8 col-md-8 col-sm-10 col-xs-11 mb-4 mb-lg-10 m-2 border-radius-sm border-b rounded shadow-2 m-5 mt-7 mb-7"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <ProjectsCard options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
