import { Link } from "gatsby";
import React from "react";
const ProjectsCard = ({ options }) => {
  return (
    <div className="d-xs-flex m-1">
      <div className="p-5 mt-3 mt-lg-0 mb-3  ">
        <Link
          className=""
          to={`/${options.link}/`}
          role="button"
          aria-expanded="false"
        >
          <h3 className="font-size-7 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pt-3 pr-lg-8 pr-xl-5">
            {options.title}
          </h3>
        </Link>
        <div className="d-flex align-items-center">
          <p className="font-size-5 mb-1">📍 {options.content}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;
